import {guestHost, useHead, useNuxtApp} from '#imports';
import {Photo} from '@malt/asset-utils';
import {sanitize} from '@malt/sanitize';
import {useProfileStore} from '~/store/profileStore';
import {breadcrumbItems} from '~/types/domain/Breadcrumb';

export const useBreadcrumbMicroData = () => {
    const {$pinia} = useNuxtApp();
    const {breadcrumb} = useProfileStore($pinia);

    const bcItems = breadcrumbItems(breadcrumb);
    if (bcItems.items.length > 0) {
        useHead({
            script: [
                {
                    type: 'application/ld+json',
                    innerHTML: JSON.stringify({
                        '@context': 'https://schema.org',
                        '@type': 'BreadcrumbList',
                        itemListElement: bcItems.items.map((item, index) => ({
                            '@type': 'ListItem',
                            position: index + 1,
                            item: {
                                '@id': guestHost() + item.url,
                                name: item.url,
                            },
                        })),
                    }),
                },
            ],
        });
    }
};

export const useProfileMicroData = () => {
    const {$pinia} = useNuxtApp();
    const profileStore = useProfileStore($pinia);
    const {appraisals, profile, displayName} = profileStore;

    const numberOfAppraisalsWithRating = appraisals?.numberOfAppraisalsWithRating || 0;
    const numberOfReviews = appraisals?.appraisals?.length || 0;

    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);

    const photoUrl = profile?.photo
        ? new Photo(profile?.photo).getFaceThumbnailUrl({width: 330, height: 325, forcedFormat: 'png'})
        : 'https://dam.malt.com/rebranding2020/illustrations/no-photo-placeholder';

    useHead({
        script: [
            {
                type: 'application/ld+json',
                innerHTML: JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'Person',
                    name: sanitize(displayName),
                    url: profile?.profileUrl,
                    image: photoUrl,
                    jobTitle: sanitize(profile?.headline),
                    address: {
                        '@type': 'PostalAddress',
                        addressLocality: sanitize(profile?.address?.city),
                        addressRegion: sanitize(profile?.address?.region),
                        addressCountry: sanitize(profile?.address?.country),
                    },
                    makesOffer: {
                        '@type': 'Offer',
                        price: !profile?.price.priceHidden ? profile?.price.price : 0,
                        priceCurrency: profile?.price.currency.code,
                        priceValidUntil: oneYearFromNow,
                        availability: 'https://schema.org/InStock',
                        itemOffered: {
                            '@type': 'Product',
                            name: sanitize(displayName),
                            description: sanitize(profile?.headline),
                            image: photoUrl,
                            offers: {
                                '@type': 'Offer',
                                price: !profile?.price.priceHidden ? profile?.price.price : 0,
                                priceCurrency: profile?.price.currency.code,
                                priceValidUntil: oneYearFromNow,
                                availability: 'https://schema.org/InStock',
                            },
                            aggregateRating:
                                numberOfAppraisalsWithRating > 0
                                    ? {
                                          '@type': 'AggregateRating',
                                          ratingValue: appraisals?.ratings.general,
                                          ratingCount: appraisals?.numberOfAppraisalsWithRating,
                                          reviewCount: numberOfReviews > 0 ? appraisals?.appraisals.length : null,
                                          bestRating: 5,
                                          worstRating: 1,
                                      }
                                    : {
                                          '@type': 'AggregateRating',
                                          ratingValue: 5,
                                          ratingCount: 1,
                                          bestRating: 5,
                                          worstRating: 1,
                                      },
                        },
                    },
                }),
            },
        ],
    });
};
